// components/Hero.js
import React from 'react';

function Hero() {
  return (
    <section className="text-center py-20 bg-gray-50">
      <h1 className="text-4xl md:text-5xl font-extrabold mb-4">Zachery Annis</h1>
      <p className="text-lg md:text-xl text-gray-600 max-w-2xl mx-auto">
        Lead Software Engineer & Politico
      </p>
    </section>
  );
}

export default Hero;
