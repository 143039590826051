// App.js
import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Bio from './components/Bio';
import Projects from './components/Projects';
import Footer from './components/Footer';

function App() {
  return (
    <div className="bg-white text-gray-900">
      {/*<Header />*/}
      <Hero />
      <Bio />
      {/*<Projects.js*/}
      <Footer />
    </div>
  );
}

export default App;
