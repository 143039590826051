// components/Bio.js
import React from 'react';

function Bio() {
  return (
    <section id="bio" className="py-20 px-8 max-w-3xl mx-auto text-center">
      <h2 className="text-3xl font-semibold mb-6">About Me</h2>
      <img 
        src="profile.jpg" 
        alt="Zachery Annis"
        className="w-40 h-40 rounded-full mx-auto mb-6"
      />
      <p className="text-gray-700 leading-relaxed mb-6">
        I’m an accomplished Lead Software Engineer with a diverse background in software development, IT support, and emerging tech. 
        I specialize in designing and building scalable solutions—from VR applications to complex data management systems.
      </p>
      <p className="text-gray-700 leading-relaxed mb-6">
        With expertise in C#, Python, JavaScript, React, Node.js, and AWS, I thrive in both independent and team-based environments.
        In addition to my technical pursuits, I’ve also been involved in political organizing, reflecting my belief in the importance of community engagement and strategic leadership. 
        Let’s connect and collaborate on something cool.
      </p>
      <div className="mt-8 text-gray-500">
        <p>Maine, USA</p>
        <p><a href="mailto:info@zacheryannis.com" className="text-blue-600 hover:underline">info@zacheryannis.com</a></p>
      </div>
    </section>
  );
}

export default Bio;
